<template>
	<div class="login medical-bg">
		<div class="main-sty">
			<div class="login-title">
				<img src="../assets/5g.png"  style="width: 200px" />
				<!-- <img src="../assets/u18.png" style="width: 56px;height: 87px;" /> -->
				<div class="div_d">起搏器随访数据平台</div>
				<div style="letter-spacing:8px;font-size: 22px;">术后随访 数据管理</div>
			</div>
			<div class="logon-form">
				<el-form :model="userInfo" ref="userInfo" class="login-form-content" >
					<el-form-item label="" class="gray-color">
						<el-input clearable class="input-width" v-model="userInfo.phone" placeholder="请输入用户名"></el-input>
					</el-form-item>
					<el-form-item  class="gray-color" v-if="ispwd">
				    <el-input clearable class="input-width" type="password" @keyup.enter.native="login" v-model="userInfo.password" placeholder="请输入密码"></el-input>
				  </el-form-item>
					<el-form-item class="gray-color" v-else>
						<el-input @keyup.enter.native="login" class="input-width" placeholder="请输入验证码" v-model="userInfo.code">
							<template slot="append">
								<div class="gk-pointer cursors " v-show="showcode" @click="getcode">获取验证码</div>
								<div class="cursors " v-show="!showcode">重新发送({{count}})</div>
							</template>
						</el-input>
					</el-form-item>
					<div style="text-align:right;">
						<el-link style="color:#ffffff;margin-bottom:20px;" :underline="false" @click="ispwd = !ispwd">切换登录模式</el-link>
					</div>
					<el-form-item>
						<el-button type="primary" style="width:100%;font-size:18px;font-weight: normal;border-radius: 20px;" @click="login">登
							录</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import pwdCrypto from '../utils/index'
	export default {
		data() {
			return {
				userInfo: {},
				showcode: true,
				count: 120,
				ispwd: true
			}
		},
		mounted() {

		},
		methods: {
			getcode() {
				let telStr = /^[1]([3-9])[0-9]{9}$/;
				if (!this.userInfo.phone) {
					return this.$message.error('请输入手机号!')
				}
				if (!(telStr.test(this.userInfo.phone))) {
					return this.$message.error('请输入正确的手机号')
				}
				this.QueryLogiAuthenti();
			},
			async QueryLogiAuthenti() {
				let info = {
					phone: this.userInfo.phone
				}
				let res = await this.$Http.LogiAuthenti(info);
				if (res.code == '200') {
					this.getCodeCount();
				} else {
					this.$message.error(res.message);
				}
			},
			getCodeCount() {
				const TIME_COUNT = 120
				if (!this.timer) {
					this.count = TIME_COUNT
					this.showcode = false
					this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--
						} else {
							this.showcode = true
							clearInterval(this.timer)
							this.timer = null
						}
					}, 1000)
				}
			},
			async QueryLogin() {
				let telStr = /^[1]([3-9])[0-9]{9}$/;
				if (!this.userInfo.phone) {
					return this.$message.error('请输入手机号!')
				}
				if (!(telStr.test(this.userInfo.phone))) {
					return this.$message.error('请输入正确的手机号')
				}
				if (this.ispwd && !this.userInfo.password) {
					return this.$message.error('请输入密码!')
				}
				if (!this.ispwd && !this.userInfo.code) {
					return this.$message.error('请输入验证码!')
				}
				let info = {
					phone: this.userInfo.phone,
					password: pwdCrypto.encrypto(this.userInfo.password),
					code: this.userInfo.code
				}
				let res = await this.$Http.Login(info);
				if (res.code == '200') {
					this.$store.commit('AuthToken', res.data.authToken);
					this.$store.commit('FullName', res.data.fullName);
					this.$store.commit('Types', res.data.type);
					this.$router.push({
						path: '/'
					})
				} else {
					this.$message.error(res.message);
				}
			},

			// 登录
			login() {
				this.QueryLogin();
			},
			// 用户注册
			toregist() {

			}
		}
	}
</script>
<style lang="scss">
	.gray-color .el-form-item__label {
		color: #ffffff;
		font-size: 24px;
		font-weight: 700;
	}

	.medical-bg {
		width: 100%;
		height: 100vh;
		// background-color: rgba(3, 147, 216, 1);
		background: url('../assets/yl_bg.jpg') no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		/*元素垂直居中*/
		justify-content: center;

		/*元素水平居中*/
		.main-sty {
			width: 600px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
      .login-title {
				color: #ffffff;
				margin-top: 20px;
        .div_d {
					font-weight: normal;
					font-size: 36px;
					letter-spacing:6px;
					margin: 10px auto;
				}
			}

			.logon-form {
				margin-top: 20px;
				// align-self: flex-end;
				color: #ffffff;
				.el-input{
					width: 288px;
				}
			}
		}

	}

	.input-width /deep/ .el-input__inner {
		/*或者 .s2>>>.el-input__inner  */
		border-radius: 20px;
		background-color:transparent;
		color:#fff;
	}

	.input-width /deep/ .el-input-group__append {
		border-radius: 20px;
	}
</style>
